<script setup lang="ts" generic="T extends unknown = boolean">
import { Field } from "vee-validate"
const props = withDefaults(
  defineProps<{
    name: string
    errorMessage?: string
    value?: T
    /**
     * The value to set when the checkbox is checked.
     */
    trueValue?: T

    /**
     * The value to set when the checkbox is unchecked.
     */
    falseValue?: T

    /**
     * The form input identifier.
     */
    id?: string

    /**
     * The label to display for the checkbox.
     */
    label?: string

    /**
     * An error message to display below the checkbox label.
     */
    error?: string | boolean

    /**
     * Whether the checkbox is disabled.
     */
    disabled?: boolean

    /**
     * Whether the checkbox is in indeterminate state.
     */
    indeterminate?: boolean

    /** The color of the checkbox.
     *
     * @default 'default'
     */
    color?:
      | "default"
      | "muted"
      | "light"
      | "dark"
      | "black"
      | "primary"
      | "info"
      | "success"
      | "warning"
      | "danger"

    /**
     * The radius of the checkbox.
     *
     * @since 2.0.0
     * @default 'sm'
     */
    rounded?: "none" | "sm" | "md" | "lg" | "full"

    /**
     * Optional CSS classes to apply to the wrapper, label, and input elements.
     */
    classes?: {
      /**
       * CSS classes to apply to the wrapper element.
       */
      wrapper?: string | string[]

      /**
       * CSS classes to apply to the label element.
       */
      label?: string | string[]

      /**
       * CSS classes to apply to the input element.
       */
      input?: string | string[]
    }
  }>(),
  {
    disabled: false,
    value: undefined,
    trueValue: true as any,
    falseValue: false as any,
    id: undefined,
    label: undefined,
    error: "",
    rounded: undefined,
    color: undefined,
    classes: () => ({}),
  },
)
</script>

<template>
  <Field
    v-slot="{ field, handleChange, handleBlur }"
    :name="name"
    type="checkbox"
    :value="value"
  >
    <BaseCheckbox
      v-bind="field"
      :name="name"
      :id="id"
      @update:model-value="handleChange"
      @blur="handleBlur"
      :label="label"
      :value="props.value"
      :true-value="props.trueValue"
      :false-value="props.falseValue"
      :classes="props.classes"
      :disabled="props.disabled"
      :rounded="props.rounded"
      :error="props.error"
    />
  </Field>
</template>

<style scoped></style>
